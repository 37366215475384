<template>
  <div>
    <v-row>
      <v-col
        class="xs"
        sm="6"
        md="4"
        lg="2"
        xl="1"
      >
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="formattedStartDate"
              label="Start Date"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="internalStartDate"
            @input="startDateMenu = false"
          />
        </v-menu>
      </v-col>
      <v-col
        class="xs"
        sm="6"
        md="4"
        lg="2"
        xl="1"
      >
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="formattedEndDate"
              label="End Date"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="internalEndDate"
            @input="endDateMenu = false"
          />
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { format, parseISO } from 'date-fns'
  export default {
    name: 'DateRange',
    components: {},
    mixins: [formatterMixin],
    props: {
      startDate: {
        type: String,
        default: () => (null),
      },
      endDate: {
        type: String,
        default: () => (null),
      },
    },
    data () {
      return {
        format,
        parseISO,
        startDateMenu: false,
        endDateMenu: false,
        internalStartDate: null,
        internalEndDate: null,
      }
    },
    computed: {
      formattedStartDate () {
        return format(parseISO(this.internalStartDate), 'MM/dd/yyyy')
      },
      formattedEndDate () {
        return format(parseISO(this.internalEndDate), 'MM/dd/yyyy')
      },
    },
    watch: {
      internalStartDate () {
        this.$emit('changedStartDate', this.internalStartDate)
      },
      internalEndDate () {
        this.$emit('changedEndDate', this.internalEndDate)
      },
    },
    created () {
      this.internalStartDate = this.startDate
      this.internalEndDate = this.endDate
    },
  }
</script>
